body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    135deg,
    rgba(25, 30, 35, 1) 0%,
    rgba(35, 40, 45, 1) 100%
  );
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 全局样式优化 */
:root {
  --primary-color: 255, 50, 50; /* 鲜明的红色 */
  --secondary-color: 50, 240, 80; /* 鲜明的绿色 */
  --accent-color: 255, 215, 0; /* 金色 */
  --text-color: 255, 255, 255; /* 白色文字 */
  --background-color: 30, 35, 40; /* 稍微亮一点的背景 */
  --gradient-start: 255, 60, 60; /* 渐变起点 - 亮红色 */
  --gradient-end: 60, 230, 90; /* 渐变终点 - 亮绿色 */
}

/* 添加页面顶部的彩色条纹 */
body:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(
    90deg,
    rgba(var(--primary-color), 1) 0%,
    rgba(var(--secondary-color), 1) 50%,
    rgba(var(--accent-color), 1) 100%
  );
  z-index: 1000;
}

/* 页面过渡动画 */
.page-transition {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* 光效动画 */
.glow-effect {
  animation: pulse 4s ease-in-out infinite;
  opacity: 1;
}

.glow-effect.primary {
  animation-delay: 0s;
}

.glow-effect.secondary {
  animation-delay: 2s;
}

@keyframes pulse {
  0% {
    opacity: 0.85;
    transform: translateX(-50%) scale(0.95);
    filter: blur(35px);
  }
  50% {
    opacity: 1;
    transform: translateX(-50%) scale(1.05);
    filter: blur(40px);
  }
  100% {
    opacity: 0.85;
    transform: translateX(-50%) scale(0.95);
    filter: blur(35px);
  }
}

/* 设备反射效果 */
.device-reflection {
  animation: reflection 8s ease-in-out infinite;
}

@keyframes reflection {
  0% {
    opacity: 0.6;
    transform: translateX(-50%) rotateX(75deg) scale(0.85, 0.3);
    filter: blur(4px);
  }
  50% {
    opacity: 0.8;
    transform: translateX(-52%) rotateX(75deg) scale(0.9, 0.35);
    filter: blur(6px);
  }
  100% {
    opacity: 0.6;
    transform: translateX(-50%) rotateX(75deg) scale(0.85, 0.3);
    filter: blur(4px);
  }
}

/* 确保动画在小屏幕上优雅降级 */
@media (max-width: 768px) {
  .glow-effect,
  .device-reflection {
    display: none;
  }

  .device-wrapper {
    transform: scale(0.9);
  }
}

/* 自定义按钮样式 - 修改悬浮效果为更加柔和的版本 */
.custom-button {
  background: linear-gradient(
    135deg,
    rgba(var(--primary-color), 0.9) 0%,
    rgba(var(--secondary-color), 0.9) 100%
  );
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(var(--primary-color), 0.3);
}

.custom-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(var(--primary-color), 0.4);
  background: linear-gradient(
    135deg,
    rgba(var(--primary-color), 1) 0%,
    rgba(var(--secondary-color), 1) 100%
  );
}

.custom-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 10px rgba(var(--primary-color), 0.3);
}

/* 游戏主题按钮 - 修改悬浮效果为更加柔和的版本 */
.game-button {
  position: relative;
  background: linear-gradient(
    135deg,
    rgba(var(--primary-color), 0.8) 0%,
    rgba(var(--secondary-color), 0.8) 100%
  );
  color: white;
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  overflow: hidden;
  box-shadow: 0 6px 20px rgba(var(--primary-color), 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1) inset;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.game-button:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: linear-gradient(
    45deg,
    rgba(var(--primary-color), 0.9),
    rgba(var(--secondary-color), 0),
    rgba(var(--accent-color), 0.9),
    rgba(var(--primary-color), 0)
  );
  background-size: 400% 400%;
  border-radius: 12px;
  animation: gradientBorder 4s ease infinite;
  opacity: 0.8;
}

@keyframes gradientBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.game-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 25px rgba(var(--primary-color), 0.4),
    0 0 0 1px rgba(255, 255, 255, 0.15) inset;
}

.game-button:hover:before {
  opacity: 1;
}

.game-button:active {
  transform: translateY(1px);
  box-shadow: 0 4px 15px rgba(var(--primary-color), 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.1) inset;
}

/* 应用商店按钮悬停效果 */
.store-badge-hover {
  transition: all 0.3s ease;
  filter: drop-shadow(0 5px 15px rgba(var(--primary-color), 0.3));
}

.download-button-wrapper:hover .store-badge-hover {
  transform: translateY(-5px);
  filter: drop-shadow(0 10px 20px rgba(var(--primary-color), 0.4));
}

/* 边框流光效果 */
.title-glow-border {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 0 auto;
}

.title-glow-border:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  padding: 2px;
  background: linear-gradient(
    135deg,
    rgba(var(--primary-color), 1) 0%,
    rgba(var(--secondary-color), 1) 33%,
    rgba(var(--accent-color), 1) 66%,
    rgba(var(--primary-color), 1) 100%
  );
  background-size: 300% 300%;
  animation: glowingBorder 3s linear infinite;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

@keyframes glowingBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
